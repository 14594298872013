<template>
  <div
    :class="[
      'cd__actions-wrapper sticky-main',
      { 'pre-login': !isAuthenticated }
    ]"
  >
    <div
      class="cd__action-div"
      :class="
        getSelectedCourse.intro_video_url ? 'cd__video-url' : 'cd__video-no-url'
      "
    >
      <!-- video preview -->
      <div
        class="intro_video_url d-none d-lg-block"
        v-if="isAuthenticated && getSelectedCourse.intro_video_url"
        :style="{ 'background-image': 'url(' + youtubeThumbnail + ')' }"
      >
        <img
          src="@/assets/images/icons/play-blue.svg"
          alt="play"
          width="auto"
          height="auto"
          class="c-pointer"
          @click="$bvModal.show('intro-video-modal')"
        />
      </div>

      <!-- call to action -->
      <div class="cd__course-card">
        <div
          class="title-register  center d-none d-lg-block"
          v-if="!isAuthenticated"
        >
          <a class="cd__register--link" href="javascript:;" @click="signUp">{{
            $t("general.register")
          }}</a>
          <span class="cd__start-learning">{{
            $t("general.to_start_learning")
          }}</span>
        </div>
        <div class="cd__custom-button--div">
          <div
            v-if="isTitleDisplay"
            id="course-title"
            class="d-none d-lg-block"
          >
            {{ getSelectedCourse.title }}
          </div>

          <!-- enroll now -->
          <transition name="fade" mode="out-in">
            <div
              class="pre__requisite--course"
              v-if="
                cStep === 1 &&
                  getSelectedCourse?.pre_requisite_courses?.length > 0 &&
                  isAuthenticated &&
                  !getSelectedCourse?.has_met_prerequisites
              "
              :key="1"
              ref="pre__requisite--action"
            >
              <LxpButton
                class="btn-locked"
                size="default"
                block
                variant="grey"
                disabled
              >
                <p>{{ $t("course.locked") }}</p>
                <img
                  src="@/assets/images/course-details/information-grey.svg"
                  alt="information"
                />
              </LxpButton>
              <b-tooltip
                :target="() => $refs['pre__requisite--action']"
                triggers="hover focus"
                placement="bottom"
                :custom-class="[
                  'prerequisite-tooltip',
                  { 'alert__tooltip--top': isSystemAlertEnabled }
                ]"
              >
                <div class="cd__tooltip--inner">
                  {{ $t("course.tooltip.prerequisite") }}
                </div>
              </b-tooltip>
            </div>
            <div v-else-if="cStep === 1" :key="2">
              <LxpButton
                :class="{ 'gradient-bg': !isAuthenticated }"
                size="default"
                block
                v-if="isEnrollEnabled"
                @click="onEnroll(2)"
              >
                <img
                  src="@/assets/images/courseware/school-responsive-white.svg"
                  alt="school"
                  class="d-lg-none"
                />
                <p>{{ $t("course.enroll_now") }}</p>
              </LxpButton>

              <div
                v-else-if="
                  hasFutureEnrollmentDate && getSelectedCourse.can_enroll
                "
                ref="cd__actions-enrollsoon"
              >
                <LxpButton variant="grey" disabled size="default" block>
                  <img
                    src="@/assets/images/courseware/school-responsive-grey.svg"
                    alt="school"
                    class="d-lg-none"
                  />
                  <p>{{ $t("course.enrollment_status.enroll_soon") }}</p>
                </LxpButton>
                <b-tooltip
                  :target="() => $refs['cd__actions-enrollsoon']"
                  triggers="hover focus"
                  placement="bottom"
                  variant="course"
                >
                  <div class="cd__tooltip--inner">
                    <p>{{ enrollSoonTooltipData }}</p>
                  </div>
                </b-tooltip>
              </div>
              <!-- enrollment closed -->
              <div v-else-if="hasPassedEnrollmentDate">
                <LxpButton variant="grey" size="default" disabled block>
                  <img
                    src="@/assets/images/courseware/school-responsive-grey.svg"
                    alt="school"
                    class="d-lg-none"
                  />
                  <p>{{ $t("course.enrollment_status.enrollment_closed") }}</p>
                </LxpButton>
              </div>

              <div v-else ref="cd__actions-invitation">
                <LxpButton variant="grey" disabled size="default" block>
                  <img
                    src="@/assets/images/courseware/school-responsive-grey.svg"
                    alt="school"
                    class="d-lg-none"
                  />
                  <p>{{ $t("course.enroll_now") }}</p>
                </LxpButton>
                <b-tooltip
                  :target="() => $refs['cd__actions-invitation']"
                  triggers="hover focus"
                  placement="bottom"
                  variant="course"
                >
                  <div class="cd__tooltip--inner">
                    <p>{{ tooltipData }}</p>
                  </div>
                </b-tooltip>
              </div>
            </div>

            <div v-else-if="cStep === 2" :key="3">
              <transition name="fade" mode="out-in">
                <Button
                  variant="primary-lighter"
                  v-if="enrolled"
                  class="cd__custom-button--style cd__enrolled--style"
                  :pill="true"
                  :block="true"
                  :key="1"
                >
                  <img
                    src="@/assets/images/courseware/school-responsive-white.svg"
                    alt="school"
                    class="d-lg-none"
                  />
                  <p>{{ $t("course.enrollment_status.enrolled") }}</p>
                  <b-icon icon="check-lg" class="check_icon"></b-icon>
                </Button>

                <LxpButton
                  class="cd__start-learning"
                  size="default"
                  variant="accent"
                  block
                  @click="onEnroll(3)"
                  :key="2"
                  v-if="
                    !hasFutureEnrollmentDate &&
                      !hasFutureStartDate &&
                      startLearning &&
                      !isCourseEnded
                  "
                >
                  <img
                    src="@/assets/images/courseware/school-responsive-white.svg"
                    alt="school"
                    class="d-lg-none"
                  />
                  <p>{{ $t("course.start_learning") }}</p>
                </LxpButton>
                <div ref="cd__actions-startingsoon">
                  <LxpButton
                    variant="grey"
                    disabled="true"
                    size="default"
                    :key="3"
                    block
                    v-if="!hasFutureEnrollmentDate && hasFutureStartDate"
                  >
                    <img
                      src="@/assets/images/courseware/school-responsive-grey.svg"
                      alt="school"
                      class="d-lg-none"
                    />
                    <p>{{ $t("course.enrollment_status.starting_soon") }}</p>
                  </LxpButton>
                  <b-tooltip
                    :target="() => $refs['cd__actions-startingsoon']"
                    triggers="hover focus"
                    placement="bottom"
                    variant="course"
                  >
                    <div class="cd__tooltip--inner">
                      <p>{{ startingSoonTooltipData }}</p>
                    </div>
                  </b-tooltip>
                </div>
              </transition>
            </div>

            <div v-else-if="cStep === 3" :key="4">
              <Button
                variant="primary-lighter"
                :pill="true"
                :block="true"
                @click="onEnroll(3)"
                class="cd__custom-button--style cd__resume--style"
              >
                <img
                  src="@/assets/images/courseware/school-responsive-white.svg"
                  alt="school"
                  class="d-lg-none"
                />
                <p>{{ $t("course.enrollment_status.resume") }}</p>
              </Button>
            </div>

            <div
              class="d-flex align-items-center cd-sec__complete"
              v-else-if="cStep === 4 || cStep === 5"
              :key="5"
            >
              <LxpButton
                variant="outline-primary"
                size="default"
                block
                @click="onEnroll(3)"
              >
                <p>{{ $t("course.review") }}</p>
              </LxpButton>
            </div>
          </transition>
        </div>

        <div class="cd__course-enrollment--style">
          <div class="cd__course-enrollment--div" v-if="cStep === 4">
            <div class="d-flex position-relative">
              <div class="cd__actions--icon">
                <img
                  src="@/assets/images/icons/tick-green.svg"
                  alt="success"
                  class="cd__image--style"
                />
              </div>
              <div class="cd__actions--text cd__actions--text-success">
                {{
                  getSelectedCourse.completed_at
                    ? $t("course.completed_on", {
                        s: getDate(getSelectedCourse.completed_at)
                      })
                    : $t("my_learning.tab.completed")
                }}
              </div>
            </div>
          </div>

          <div class="cd__course-enrollment--div" v-if="cStep === 5">
            <div class="d-flex position-relative" ref="cd__actions-review">
              <div class="cd__actions--icon">
                <img
                  src="@/assets/images/courseware/alert-calendar.svg"
                  alt="alert calendar"
                  class="cd__image--style"
                  width="20px"
                />
              </div>
              <div class="cd__actions--text text-warning">
                {{ $t("course.review_closed_alert.title") }}
              </div>
              <b-tooltip
                :target="() => $refs['cd__actions-review']"
                triggers="hover focus"
                placement="bottom"
                variant="course"
                :custom-class="{ 'alert__tooltip--top': isSystemAlertEnabled }"
              >
                <div class="cd__tooltip--inner">
                  <p>{{ reviewTooltipData }}</p>
                </div>
              </b-tooltip>
            </div>

            <div
              class="cd__responsive-tooltip d-block d-lg-none"
              v-html="reviewTooltipData"
            ></div>
          </div>

          <div
            class="pre__requisite--note"
            v-if="getSelectedCourse?.pre_requisite_courses?.length > 0"
          >
            <img
              :src="
                getSelectedCourse?.has_met_prerequisites
                  ? prerequisiteCompletedIcon
                  : prerequisiteIcon
              "
              alt="icon"
            />
            <div class="course-name">
              {{ $t("course.prerequisite") }}
              <button type="button" class="course-link" @click="onClick">
                {{ getSelectedCourse?.pre_requisite_courses[0]?.display_name }}
              </button>
            </div>
          </div>

          <div
            class="cd__course-enrollment--div"
            v-if="
              hasFutureEnrollmentDate &&
                (getSelectedCourse.enrollment_starts_at ||
                  getSelectedCourse.enrollment_ends_at)
            "
          >
            <div class="d-none d-lg-flex">
              <div class="cd__actions--icon">
                <img
                  src="@/assets/images/courseware/school.svg"
                  alt="school"
                  class="cd__image--style"
                  width="20px"
                  height="18px"
                />
              </div>
              <div class="cd__actions--text">
                <span v-if="getSelectedCourse.enrollment_starts_at"
                  >{{
                    $t("course.enroll_on", {
                      s: getDate(getSelectedCourse.enrollment_starts_at)
                    })
                  }}
                </span>
                <p v-if="getSelectedCourse.enrollment_ends_at">
                  {{
                    $t("course.enroll_by", {
                      s: getDate(getSelectedCourse.enrollment_ends_at)
                    })
                  }}
                </p>
              </div>
            </div>
            <div
              class="cd__responsive-tooltip d-block d-lg-none"
              v-html="enrollSoonTooltipData"
            ></div>
          </div>

          <div
            class="cd__course-enrollment--div"
            v-if="
              cStep === 1 &&
                !hasFutureEnrollmentDate &&
                !hasPassedEnrollmentDate &&
                getSelectedCourse.enrollment_ends_at
            "
          >
            <div class="d-none d-lg-flex">
              <div class="cd__actions--icon">
                <img
                  src="@/assets/images/courseware/school.svg"
                  alt="school"
                  class="cd__image--style"
                  width="20px"
                  height="18px"
                />
              </div>
              <div class="cd__actions--text">
                <span>
                  {{
                    $t("course.enroll_by", {
                      s: getDate(getSelectedCourse.enrollment_ends_at)
                    })
                  }}
                </span>
              </div>
              <p></p>
            </div>
            <div
              v-if="!getSelectedCourse.can_enroll"
              class="cd__responsive-tooltip d-block d-lg-none"
              v-html="tooltipData"
            ></div>
          </div>

          <div
            class="cd__course-enrollment--div"
            v-if="cStep === 1 && hasPassedEnrollmentDate"
          >
            <div class="d-none d-lg-flex">
              <div class="cd__actions--icon">
                <img
                  src="@/assets/images/courseware/school.svg"
                  alt="school"
                  class="cd__image--style"
                  width="20px"
                  height="18px"
                />
              </div>
              <div class="cd__actions--text">
                {{
                  $t("course.closed_on", {
                    s: getDate(getSelectedCourse.enrollment_ends_at)
                  })
                }}
              </div>
            </div>
          </div>

          <div
            class="cd__course-enrollment--div"
            v-if="cStep === 2 || cStep === 3"
          >
            <div class="d-none d-lg-flex">
              <div class="cd__actions--icon">
                <img
                  src="@/assets/images/courseware/school.svg"
                  alt="school"
                  class="cd__image--style"
                  width="20px"
                  height="18px"
                />
              </div>
              <div class="cd__actions--text">
                <div
                  v-if="
                    !hasFutureEnrollmentDate &&
                      hasFutureStartDate &&
                      !getSelectedCourse.is_enrolled
                  "
                >
                  <span>
                    {{
                      $t("course.enroll_on", {
                        s: getDate(getSelectedCourse.enrollment_starts_at)
                      })
                    }}
                  </span>
                  <p>
                    {{
                      $t("course.enroll_by", {
                        s: getDate(getSelectedCourse.enrollment_ends_at)
                      })
                    }}
                  </p>
                </div>
                <div v-else>
                  <span>
                    {{
                      $t("course.enrolled_on", {
                        s: getDate(getSelectedCourse.enrolled_at)
                      })
                    }}
                  </span>
                </div>
              </div>
            </div>
            <div
              v-if="cStep === 2"
              class="cd__responsive-tooltip d-block d-lg-none"
              v-html="startingSoonTooltipData"
            ></div>
          </div>

          <div class="cd__course-enrollment--div">
            <div class="d-none d-lg-flex">
              <div class="cd__actions--icon">
                <img
                  src="@/assets/images/courseware/self-paced.svg"
                  alt="self placed"
                  class="cd__image--style"
                  width="20px"
                  height="18px"
                />
              </div>
              <div class="cd__actions--text">
                {{ $t(`data.pacing.${getSelectedCourse.pacing}`) }}
              </div>
            </div>
          </div>

          <div class="cd__course-enrollment--div">
            <div class="d-none d-lg-flex">
              <div class="cd__actions--icon">
                <img
                  src="@/assets/images/courseware/translate.svg"
                  alt="translate"
                  class="cd__image--style"
                  width="20px"
                  height="18px"
                />
              </div>
              <div class="cd__actions--text">
                {{
                  getSelectedCourse.language
                    ? $t(`data.language.${getSelectedCourse.language}`)
                    : $t(`data.language.en`)
                }}
              </div>
            </div>
          </div>

          <div class="cd__course-enrollment--div" v-if="cStep === 1">
            <div class="d-none d-lg-flex">
              <div class="cd__actions--icon">
                <img
                  src="@/assets/images/courseware/calendar.svg"
                  class="cd__image--style"
                  alt="calendar"
                  width="20px"
                  height="18px"
                />
              </div>
              <div class="cd__actions--text">
                <span
                  v-if="
                    getSelectedCourse.current_server_time <
                      getSelectedCourse.course_starts_at ||
                      (!getSelectedCourse.current_server_time &&
                        hasFutureCourseStartDate)
                  "
                  >{{
                    $t("course.starting_on", {
                      s: getDate(getSelectedCourse.course_starts_at)
                    })
                  }}
                </span>
                <span
                  v-if="
                    getSelectedCourse.current_server_time >=
                      getSelectedCourse.course_starts_at ||
                      (!getSelectedCourse.current_server_time &&
                        hasPastCourseStartDate)
                  "
                >
                  {{
                    $t("course.started_on", {
                      s: getDate(getSelectedCourse.course_starts_at)
                    })
                  }}
                </span>
                <p
                  v-if="
                    getSelectedCourse.current_server_time <
                      getSelectedCourse.course_ends_at ||
                      (!getSelectedCourse.current_server_time &&
                        hasFutureCourseEndDate)
                  "
                >
                  {{
                    $t("course.ending_on", {
                      s: getDate(getSelectedCourse.course_ends_at)
                    })
                  }}
                </p>
                <span
                  v-if="
                    getSelectedCourse.current_server_time >=
                      getSelectedCourse.course_ends_at ||
                      (!getSelectedCourse.current_server_time &&
                        hasPastCourseEndDate)
                  "
                  >{{
                    $t("course.ended_on", {
                      s: getDate(getSelectedCourse.course_ends_at)
                    })
                  }}</span
                >
              </div>
            </div>
          </div>

          <div
            class="cd__course-enrollment--div"
            v-if="cStep === 2 || cStep === 3 || cStep === 4 || cStep === 5"
          >
            <div class="d-none d-lg-flex">
              <div class="cd__actions--icon">
                <img
                  src="@/assets/images/courseware/calendar.svg"
                  class="cd__image--style"
                  alt="calendar"
                  width="20px"
                  height="18px"
                />
              </div>

              <div class="cd__actions--text">
                <span
                  v-if="
                    getSelectedCourse.current_server_time <
                      getSelectedCourse.course_starts_at
                  "
                  >{{
                    $t("course.starting_on", {
                      s: getDate(getSelectedCourse.course_starts_at)
                    })
                  }}
                </span>
                <span
                  v-if="
                    getSelectedCourse.current_server_time >=
                      getSelectedCourse.course_starts_at
                  "
                  >{{
                    $t("course.started_on", {
                      s: getDate(getSelectedCourse.course_starts_at)
                    })
                  }}</span
                >
                <p
                  v-if="
                    getSelectedCourse.current_server_time <
                      this.getSelectedCourse.course_ends_at
                  "
                  style="margin-bottom: -13px;"
                >
                  {{
                    $t("course.ending_on", {
                      s: getDate(getSelectedCourse.course_ends_at)
                    })
                  }}
                </p>
                <p
                  v-if="
                    getSelectedCourse.current_server_time >=
                      getSelectedCourse.course_ends_at
                  "
                >
                  {{
                    $t("course.ended_on", {
                      s: getDate(getSelectedCourse.course_ends_at)
                    })
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- share button -->
    <div class="cd__actions-share--text d-none d-lg-block">
      <div v-if="showAlert" class="alert">
        <img src="@/assets/images/courseware/copy-check.svg" alt="share" />
        {{ $t("course.copied_to_clipboard") }}
      </div>

      <div v-else class="cd__copy--text" @click="copyUrl">
        <img src="@/assets/images/courseware/share.svg" alt="share" />
        <span class="cd__actions--share">
          {{ $t("course.share") }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
import { LxpButton } from "didactica";
// utils
import utils from "@/router/utils";
import ctaActionsMixin from "./mixins/ctaActions";

export default {
  mixins: [ctaActionsMixin],
  components: { LxpButton },
  props: {
    language: {
      type: String,
      required: true
    },
    allConfig: {
      type: Object,
      required: true
    },
    cStep: {
      type: Number,
      required: true
    },
    hasFutureEnrollmentDate: {
      type: Boolean,
      required: true
    },
    tooltipData: {
      type: String,
      required: true
    },
    isEnrollEnabled: {
      type: Boolean,
      required: true
    },
    isTitleDisplay: {
      type: Boolean,
      required: true
    },
    enrollSoonTooltipData: {
      type: String,
      required: true
    },
    startingSoonTooltipData: {
      type: String,
      required: false
    },
    reviewTooltipData: {
      type: String,
      required: false
    },
    hasPassedEnrollmentDate: {
      type: Boolean,
      required: true
    },
    hasFutureStartDate: {
      type: Boolean,
      required: true
    },
    isCourseEnded: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      showAlert: false,
      startLearning: true,
      enrolled: false,
      prerequisiteIcon: require("@/assets/images/course-details/prerequisite.svg"),
      prerequisiteCompletedIcon: require("@/assets/images/course-details/prerequisite-completed.svg")
    };
  },
  computed: {
    ...mapGetters(["getSelectedCourse", "isSystemAlertEnabled"]),
    isAuthenticated() {
      return this.$keycloak.authenticated;
    },
    hasFutureCourseStartDate() {
      return moment(new Date()).isBefore(
        moment(this.getSelectedCourse.course_starts_at)
      );
    },
    hasPastCourseStartDate() {
      return moment(new Date()).isAfter(
        moment(this.getSelectedCourse.course_starts_at)
      );
    },
    hasFutureCourseEndDate() {
      return moment(new Date()).isBefore(
        moment(this.getSelectedCourse.course_ends_at)
      );
    },
    hasPastCourseEndDate() {
      return moment(new Date()).isAfter(
        moment(this.getSelectedCourse.course_ends_at)
      );
    }
  },
  methods: {
    signUp() {
      this.$keycloak.login({
        redirectUri: this.redirectUri,
        action: "register",
        locale: this.language
      });
    },
    onEnroll(step) {
      if (!this.$keycloak.authenticated) {
        this.$keycloak.login({
          redirectUri: utils.getRedirectUri(),
          locale: this.language
        });
      }

      if (step === 2) {
        this.startLearning = false;
        this.enrolled = true;
        setTimeout(() => {
          this.enrolled = false;
          this.startLearning = true;
        }, 3000);
      }
      this.$emit("onEnroll", step);
    },
    unenroll() {
      this.$emit("unenroll");
    },
    getDate(d) {
      return d ? moment(d).format("MMM Do, YYYY") : null;
    },
    onClick() {
      this.$router.push({
        name: "Courses Detail",
        params: {
          id: this.getSelectedCourse?.pre_requisite_courses[0]?.course_id
        }
      });
      document.getElementById("app").scrollIntoView();
    }
  }
};
</script>
<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  opacity: 1;
  transition: opacity 0.3s ease;
}

.fade-leave-to,
.fade-enter {
  opacity: 0;
}
.check_icon {
  padding-left: 5px !important;
}
.lxp-button.btn.btn-locked {
  display: flex;
  line-height: normal !important;
  img {
    margin-left: 4px;
  }
}
.cd__actions-wrapper {
  .cd__action-div {
    box-shadow: 2px 5px 10px 4px rgba(0, 0, 0, 0.15);
    background: $brand-neutral-0;
    &.cd__video-url {
      border-radius: 24px 24px 10px 10px;
    }

    &.cd__video-no-url {
      border-radius: 10px;
    }

    .intro_video_url {
      border-radius: 24px 24px 0px 0px;

      img {
        position: absolute;
        top: 40%;
        left: 45%;
      }
    }
  }

  .cd__course-card {
    padding: 1rem;
  }
  .title-register {
    @include label-large;
    font-weight: 500;
    text-align: center;
    padding-bottom: 10px;
    .cd__register--link {
      text-decoration: underline;
    }
    .cd__start-learning {
      color: $brand-primary;
      margin: 5px;
    }
  }

  #course-title {
    padding-bottom: 16px;
    text-align: left;
    color: $brand-public-primary;
    @include button-label;
    letter-spacing: 0.15px;
    word-break: break-word;
  }

  .cd__custom-button--div {
    padding-bottom: 16px;

    .cd__custom-button--style {
      padding-top: 16px;
      padding-bottom: 16px;
      border-color: transparent;
      white-space: nowrap !important;
      height: 56px !important;
      &.cd__resume--style {
        background: $brand-primary-400;
        color: $brand-neutral-0;
      }
    }
    .btn-grey {
      padding: 16px !important;
    }
    .cd__start-learning {
      padding: 16px !important;
    }
    .btn,
    .btn p {
      text-transform: lowercase;
      &:first-letter {
        text-transform: uppercase;
      }
    }
    .btn p {
      margin: 0;
    }
  }

  .cd__course-enrollment--style {
    @include label-small;

    .cd__img-div {
      padding-right: 4px;
      padding-top: 10px;
    }
  }

  .cd__actions--text-success {
    @include label-large;
    color: #00c781 !important;
  }

  .cd__course-enrollment--div {
    padding-bottom: 16px;
    &:last-child {
      padding-bottom: 8px;
    }

    .cd__actions--text {
      @include label-large;
      color: $brand-neutral-900;
      font-weight: 500;
      text-align: left;

      p {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .cd__actions--date {
      font-weight: 400;
      padding-left: 2px;
    }
  }

  .cd__actions-share--text {
    text-align: center;
    padding-top: 14px;

    .alert {
      @include label-large;
      text-align: center;
      letter-spacing: 0.25px;
      color: $brand-neutral-800;
      padding: 8px;
      gap: 8px;
    }

    .cd__copy--text {
      @include body-medium;
      @include flex-horizontal-center;
      color: $brand-primary-400;
      border-radius: 100px;
      cursor: pointer;

      .cd__actions--share {
        margin-left: 10px;
        align-self: center;
      }
    }
  }

  .cd__actions--icon {
    @include flex-horizontal-center;
    padding-right: 10px;
  }
  .cd__icon-margin {
    margin-bottom: 20px;
  }

  .cd__actions--review {
    background: $brand-neutral-0;
    color: $brand-primary;
    border: 1px solid $brand-primary !important;
  }

  .pre__requisite--note {
    display: flex;
    align-items: flex-start;
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid $brand-neutral-200;
    width: 100%;
    &:not(:first-child) {
      padding-top: 16px;
      border-top: 1px solid $brand-neutral-200;
    }
    .course-name {
      @include flex-column-start;
      @include label-large($brand-neutral-700, 500);
      margin-left: 8px;
    }
    .course-link {
      @include body-medium;
      color: $brand-primary-400;
      border: none;
      background-color: transparent;
      padding: 0;
      margin: 0;
      word-break: break-word;
      text-align: left;
      max-width: 200px;
    }
  }
}

.cd__tooltip--inner {
  @include center;

  p {
    margin-bottom: 2px;
    @include body-medium;
    color: $brand-neutral-900 !important;
  }
}
.prerequisite-tooltip {
  .tooltip-inner {
    text-align: left;
    color: $brand-primary-100;
    @include body-medium;
    max-width: 330px;
  }
}
.alert__tooltip--top {
  top: -15px;
}

.b-tooltip {
  &.b-tooltip-course {
    .tooltip-inner {
      .cd__tooltip--inner {
        @include center;
        p {
          margin-bottom: 2px;
          @include label-large($brand-neutral-900 !important, 400);
        }
      }
    }
  }
}
.app-es {
  .cd__actions-wrapper {
    .cd__custom-button--div {
      .btn {
        font-size: 16px;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .cd__actions-wrapper {
    & .sticky-main {
      width: 100% !important;
    }

    .cd__custom-button--div {
      padding-bottom: 0px;

      .cd__custom-button--style {
        img {
          margin-right: 10px;
        }
      }
    }

    .cd__course-enrollment--div {
      padding-bottom: 0px;

      &:first-child {
        > div {
          &:first-child {
            padding-top: 10px;
          }
        }
      }

      .cd__actions--text {
        @include label-large;
        font-weight: 500;
        text-align: left;
      }

      > div {
        &.d-flex {
          justify-content: center;
        }
      }

      .cd__responsive-tooltip {
        margin-top: 12px;
        > div {
          justify-content: center;

          .cd__tooltip--text {
            @include body-medium;
            color: $brand-neutral-300;
            margin-top: 12px;
            text-align: center;
          }
        }
      }
    }

    .cd__actions--text {
      color: $brand-neutral-200;
    }
  }
}

[dir="rtl"] {
  .cd__actions-wrapper {
    #course-title {
      text-align: right;
    }

    .cd__image--style {
      margin-left: 0;
      margin-right: 10px;
    }

    .cd__actions--icon {
      padding-right: 0px;
      padding-left: 10px;
    }

    .cd__copy--text {
      .cd__actions--share {
        margin-right: 10px;
      }
    }

    .cd__custom-button--div {
      .cd__custom-button--style {
        img {
          margin-right: 0;
          margin-left: 10px;
        }
      }
    }

    .cd__course-enrollment--div {
      .cd__actions--text {
        text-align: right;
      }
    }

    .cd__actions--date {
      padding-left: 0px;
      padding-right: 2px;
    }
  }
}
</style>
